/* Container for the entire bottom tab */
.bottom-tab-container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #2D4E69;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }
  
  /* Inner container to align buttons horizontally */
  .bottom-tab-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 448px; /* Approximately 28rem */
    margin: 0 auto;
    height: 60px;
  }
  
  /* Bottom tab button styling */
  .bottom-tab-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: #cccccc; /* Default text color */
    padding: 0;
  }
  
  /* Active state for buttons (changes text color) */
  .bottom-tab-button.active {
    color: #4BB869;
  }
  

  /* Global button image styling (if not overridden) */
  button img {
    width: 24px;
    height: 24px;

  }
  
  /* Styling for button text */
  button p {
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 2;
    text-align: center;
    color: inherit; /* Inherits current color (active or default) */
    margin: 0;
  }
  
  /* Container for images within buttons (maintaining height) */
  .bottom-tab-img-container {
    height: 17px;
  }
  
  /* Utility classes for margin-bottom */
  .mb-1 {
    margin-bottom: 4px; /* Approximately 0.25rem */
  }
  
  .mb-2 {
    margin-bottom: 8px; /* Approximately 0.5rem */
  }
  
  /* Deposit button: circular icon container, positioned above the button */
  .deposit-icon-container {
    position: absolute;
    z-index: 40;
    top: -39px;
    width: 57px;
    height: 57px;
    background-color: #4BB869;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* WhatsApp Icon Floating */
.whatsapp-container {
  position: fixed;
  bottom: 80px; /* Adjust to avoid overlapping */
  right: 20px;
  z-index: 50;
}