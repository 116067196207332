/* ===============================
   Utility Classes (Custom Replacements)
   =============================== */
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.width-200 {
  width: 200px;
}

.width-full {
  width: 100%;
}

/* Use percentage widths for columns */
.w-8-12 {
  width: 66.67%;
}

.w-4-12 {
  width: 33.33%;
}

.my-5 {
  margin: 20px 0; /* Adjust as needed */
}

.mb-2 {
  margin-bottom: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.h-full {
  height: 100%;
}

.p-4 {
    padding: 1rem;
}

.max-w-md {
    max-width: 28rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

/* ===============================
   Deposit Form Specific Styles
   =============================== */

/* Container for the entire deposit form */
.deposit-amount {
    margin-top: 20px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  padding: 25px 25px;
  margin-bottom: 20px;
  background-image: linear-gradient(#2E506C, #152337);
}


.withdraw-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Optional backdrop */
}


.withdraw-modal{
  box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
  width: auto;
  
  height: auto;
  border-radius: 10px;
  border: 1px solid #0000001A;
  padding: 20px 60px;
  background-color: #fff;
}
.payment-success {
  display: flex
;
  /* align-content: center; */
  /* flex-wrap: wrap; */
  flex-direction: column;
}
.payment-success img{
  width: 50px;
  height: 50px;
  display: flex
;
  justify-content: center;
  align-self: center;
}

.payment-success button {
  border: none !important;
  background: none;
  padding: 0px 13px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
}
.payment-success h2{
  font-family: "Inter",sans-serif;
  font-size: 14px !important;
  margin-top: 5px;
  font-weight: 400;
  padding: 5px 0px;
  margin: 0px;
  
  text-align: center;
  color: #474747;

}
.payment-success p{
  font-family: "Inter",sans-serif;
  font-size: 26px;
  font-weight: 600;
  margin: 0px !important;
  padding: 0px !important;
  text-align: center;    
  color: #474747;

}
/* Basic form-group override */
.form-group {
  margin-bottom: 10px;
}

/* Input styling (for .form-control) */
.form-control {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
}

/* Amount Box */
.amount-box {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
              border: 1px solid #ffffff55; 
  background-color: #35353A;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 20px;
  margin-bottom: 20px !important;
}

/* Label inside Amount Box */
.amount-box label {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #ffffff;
}

/* Input inside Amount Box */
.amount-box input {
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  color: #ffffff;
  background-color: #35353A;
  padding: 0px 0 !important;
  height: 48px !important;
  
}

/* Account Details Section */
.account-details {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
              border: 1px solid #ffffff55; 
  background-color: #35353A;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px 20px 5px 20px;
  margin-bottom: 20px !important;
  
}

.title_h1 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  color: #ffffff;
  
}

.deposit-amount h1 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  color: #ffffff;
  
}


.account-details h3 {
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  font-weight: 300;
  line-height: 14.52px;
  text-align: left;
  margin-bottom: 5px;
  margin-right: 10px;
  color: #ffffff;
  margin-left: 0px !important;
}

.choose-file {
    display: contents;
}

/* Wallet Name */
.wallet-name {
  margin-bottom: 0px;
}
.copy-button {
    margin-left: 25px;
    background-color: #35353A;
    box-shadow: none;

}
.copy_btn {
    background-color: #35353A;
    box-shadow: none;
    border: none;
}

/* Copy Button */
.copy-button img {
  width: 35px;
  height: 35px;
 
  background-color: #35353A;
}

.copy-button p {
  font-family: "Inter", sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 9.68px;
  text-align: left;
  color: #27312C;
}

/* Payment Proof Section */
.payment-proof {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
              border: 1px solid #ffffff55; 
              background-color: #35353A;
              border-radius: 8px;
  overflow: hidden;
  padding: 10px 20px;
  height: 50px;
  margin-bottom: 20px;
}

.payment-proof label {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #ffffff;
}

/* Deposit File Upload */
.deposit-file-upload {
    border: 1px solid #ffffff55; 
  width: 130px;
  height: 31px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

/* Confirm Deposit Button */
.confirm-deposit button {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
  /* background: #F09C67; */
  background-image: linear-gradient(#2E506C, #152337);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  height: 50px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
  width: 100%;
  color: #ffffff;
}

.confirm-deposit button:hover {
  background-image: linear-gradient(#2E506C, #0e1825);
}

/* Modal Styles */
.modal {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
              0px 16px 16px 0px rgba(0, 0, 0, 0.09),
              0px 37px 22px 0px rgba(0, 0, 0, 0.05),
              0px 65px 26px 0px rgba(0, 0, 0, 0.02),
              0px 102px 28px 0px rgba(0, 0, 0, 0.0);
  width: 320px;
  height: 231px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  padding: 20px;
  background-color: #fff;
}






/* Custom Checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.amount-box input {
    outline: none;          /* Removes default outline on most browsers */
    border: none; 
              /* Removes any default border */
  }
  
  /* Specifically target the focus state */
  .amount-box input:focus {
    outline: none !important;    /* Override browser outline */
    box-shadow: none !important; /* Remove any box-shadow highlight */
    border: none !important;     /* Remove border if any */
    background-color: #35353A;
    color: white;

    
  }

  .amount-box input::-webkit-input-placeholder {
    color: #ffffff60;
  }
  .amount-box input::-moz-placeholder {
    color:#ffffff60;
  }
  .amount-box input:-ms-input-placeholder {
    color: #ffffff60;
  }
  .amount-box input:-moz-placeholder {
    color: #ffffff60;
  }

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #333;
  border-radius: 4px;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #1849D6;
  border-color: #1849D6;
}

.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.dialog {
  background: none;
}
.deposit-text {
  background: none !important;
  border-radius: 5px;
  color: #000 !important;
  font-size: 11px !important;
  font-weight: 500;
  /* padding: 3px 5px 5px 0; */
  padding: 0;
  margin-bottom: 5px;
  width: 65px;
}

.deposit-easypaisa {
  display: flex
;
  height: 55px;
  border-radius: 3px;
  width: 65px;
  background: #fff;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: column-reverse;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.deposit-easypaisa img {
  height: 30px;
  margin-top: 4px;
  padding: 5px;
  width: auto;
  display: flex
;
  justify-self: center;
  /* margin-right: 10px; */
}


.method-active {
  border: 2.5px solid #6cdd00 !important;
  border-radius: 5px !important;
}

.deposit-easypaisa input[type="radio"] {
  opacity: 0; 
}

.fieldset-deposit {
  width: auto !important;
  margin: auto;
  padding: 10px 34px;
}



.modal {
  background: none !important;
}


.label_for_cards {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-content: center !important;
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.toast-success {
  background-color: #28a745;
}

.toast-error {
  background-color: #dc3545;
}

.btn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.3s;
}

.btn:hover {
  opacity: 0.8;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

