.history-area{
    overflow-y: auto;
    padding-bottom: 60px !important;
    padding-top: 60px !important;
}
.history-area::-webkit-scrollbar{
    display: none;
}

.scrollable-transactions::-webkit-scrollbar{
    display: none;
}

.history-area .withdraw-items{
    /* box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000; */
    box-shadow: 0px 0px 0px 0px #0000001A, 0px 7px 19px 0px #00000017, 0px 0px 2px 0px #0000000D, 0px 7px 3px 0px #00000003, 0px 2px 2px 0px #00000000;
    border: 1px solid #0000001A;

    border-radius: 20px;
    padding: 15px;
}
.history-items{
    border: 1px solid #0000001A;
    /* box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000; */
    box-shadow: 0px 0px 0px 0px #0000001A, 0px 7px 19px 0px #00000017, 0px 0px 2px 0px #0000000D, 0px 7px 3px 0px #00000003, 0px 2px 2px 0px #00000000;
    /* background-color: #fff; */
    background-image: linear-gradient(#2E506C, #152337);
    border-radius: 20px;
    padding: 15px;

}
.history-items h2{
  
        font-size: 12px !important;
        font-weight: 700;
        line-height: 14.52px;
        text-align: left;
        color: #ffffff;
        padding-bottom: 8px;
    
}
.history-items h3 {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 0.5px;
    text-align: left;
    color: #ffffff;
    padding-bottom: 3px;
}
.history-items h3 strong{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    color: #ffffff;
}

.history-items button{
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    height: 49px;
    border-radius: 20px;
    padding: 0 20px;
}
.history-items  p{
    font-family: "Inter",sans-serif;
    font-size: 8px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    color: #ffffff;

}
.history-items  img{
    width: 31px;
    height: 31px;
    display: block;
}

/* Container for the entire history area */
.history-area {
    padding: 0.6rem;
    max-width: 30rem;
    margin: 0px auto;     /* equivalent to Tailwind's mx-auto */
  }
  
  /* Each history item container */
  .history-item {
    margin-bottom: 1.25rem;  /* equivalent to Tailwind's mb-5 */
  }
  
  /* Row container: flex layout for each history item */
  .history-item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Left column (approximately 66.67% width) */
  .history-item-left {
    width: 66.67%;
  }
  
  /* Right column (approximately 33.33% width) with vertical centering */
  .history-item-right {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Image styling with margin-bottom (similar to mb-2) */
  .history-item-img {
    margin-bottom: 0.5rem;
    /* You can also set a fixed width/height if needed:
       width: 21.5px;
       height: 21.5px;
    */
    filter: brightness(0) invert(1);
  
  }
  
  /* Timestamp styling (optional customization) */
  .history-item-timestamp {
    /* Customize font-size, color, etc., as needed */
    margin: 0;
    color: #ffffff;
  }


  /* Make the header fixed at the top */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(#2E506C, #152337);
    z-index: 1000;              /* ensures header stays above other content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Container to center content and set a minimum height */
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    padding: 0 1rem;
  }
  
  /* Logo styling */
  .header-logo {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }
  
  /* Title styling */
  .header-title {
    color: #fff;
    margin-left: 5px;
    font-size: 14px !important;
    margin: 0;
  }
  
  