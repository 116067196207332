/* Confirm Deposit Button */
.confirm-deposit button {
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10),
                0px 16px 16px 0px rgba(0, 0, 0, 0.09),
                0px 37px 22px 0px rgba(0, 0, 0, 0.05),
                0px 65px 26px 0px rgba(0, 0, 0, 0.02),
                0px 102px 28px 0px rgba(0, 0, 0, 0.0);
    /* background: #F09C67; */
    background-image: linear-gradient(#2E506C, #152337);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    height: 50px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
    width: 100%;
    color: #ffffff;
  }
  
  .confirm-deposit button:hover {
    background-image: linear-gradient(#2E506C, #0e1825);
  }

  .fieldset {
    width: auto;
    margin: auto;
    padding: 10px 40px;
}
  